import React from "react";
import {
  CarouselProvider,
  Slider,
  Image,
  Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";

const images = [
  require("./assets/images/carousel-1.jpg"),
  require("./assets/images/carousel-2.jpg"),
  require("./assets/images/carousel-3.jpg"),
  require("./assets/images/carousel-4.jpg"),
];

export function Carousel() {
  return (
    <CarouselProvider
      naturalSlideWidth={125}
      naturalSlideHeight={94}
      totalSlides={images.length}
      isPlaying
      infinite
      // className="max-h-36"
    >
      <Slider>
        {images.map((img, i) => (
          <Slide index={i}>
            <Image src={img} hasMasterSpinner={false} />
          </Slide>
        ))}
      </Slider>
    </CarouselProvider>
  );
}
