import React from "react";

export default function Header() {
  return (
    <div className="max-w-2xl  mx-auto text-center py-10 px-5 sm:py-12 sm:px-8 lg:px-10">
      <h2 className="text-lg font-extrabold sm:text-xl">
        <span className="block text-6xl font-['Arkipelago']">
          Akshay + Taylor
        </span>
        <span className="block">September 16, 2022 • Ottawa, ON</span>
      </h2>
    </div>
  );
}
