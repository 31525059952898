import React, { useEffect, useMemo, useState } from "react";
import { v4 } from "uuid";

export function Photos() {
  const baseUrl =
    "https://sukinj0b26.execute-api.ca-central-1.amazonaws.com/prod/photo";

  const [imageFiles, setImageFiles] = useState<File[]>([]);
  const [images, setImages] = useState<(string | ArrayBuffer)[]>([]);
  const [uploading, setUploading] = useState<boolean>(false);
  const [done, setDone] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const uuid: string = useMemo(v4, []);

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (files && files.length) {
      setImageFiles(Array.from(files));
    } else {
      setImageFiles([]);
    }
  };

  const inflectedPhotos = `${images.length} ${
    images.length === 1 ? "photo" : "photos"
  }`;

  const buttonText = done
    ? "Done!"
    : uploading
    ? `Uploading ${inflectedPhotos}...`
    : `Submit ${inflectedPhotos}`;

  const submitPhotos = () => {
    setUploading(true);

    const promise = Promise.all(
      imageFiles.map(async (img) => {
        return fetch(`${baseUrl}/${uuid}/${img.name}`, {
          method: "POST",
          body: await img.arrayBuffer(),
          headers: {
            "Content-Type": img.type,
          },
        });
      })
    );

    promise
      .then(() => {
        setUploading(false);
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        setUploading(false);
        setDone(true);

        setTimeout(() => {
          setDone(false);
          window.location.reload();
        }, 5000);
      });
  };

  useEffect(() => {
    const images: (string | ArrayBuffer)[] = [];
    const fileReaders: FileReader[] = [];
    let isCancel = false;
    if (imageFiles.length) {
      imageFiles.forEach((file) => {
        const fileReader = new FileReader();
        fileReaders.push(fileReader);
        fileReader.onload = (e) => {
          const result = e.target?.result;
          if (result) {
            images.push(result);
          }
          if (images.length === imageFiles.length && !isCancel) {
            setImages(images);
          }
        };
        fileReader.readAsDataURL(file);
      });
    }
    return () => {
      isCancel = true;
      fileReaders.forEach((fileReader) => {
        if (fileReader.readyState === 1) {
          fileReader.abort();
        }
      });
    };
  }, [imageFiles]);

  return (
    <>
      <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8">
          <div>
            <h2 className="mt-6 text-center text-2xl font-extrabold text-gray-900">
              Share your memories!
            </h2>
            <p className="mt-2 text-gray-600 prose">
              We'd love to see your memories, both of us before our wedding, and
              at the wedding! If the form below doesn't work, you may also email
              us at{" "}
              <a href="mailto:wedding@photos.akshayandtaylor.com">
                wedding@photos.akshayandtaylor.com
              </a>
              .
            </p>
          </div>

          <div>
            <div>
              <input
                type="file"
                accept="image/*,video/*"
                multiple
                onChange={onFileChange}
              />
            </div>
            {images && images.length > 0 && (
              <>
                <button
                  type="button"
                  className="
                    mt-8 w-full inline-flex items-center justify-center px-5 py-3
                    border border-transparent text-base font-medium rounded-md text-white bg-red-600 no-underline hover:bg-red-800 hover:text-white sm:w-auto
                    disabled:bg-red-100 disabled:text-gray-700 disabled:border-red-200 disabled:shadow-none
                  "
                  disabled={uploading}
                  onClick={submitPhotos}
                >
                  {buttonText}
                </button>
                {!done && (
                  <div className="container grid grid-cols-3 gap-2 mx-auto mt-4">
                    {images.map((image) => {
                      const reader = new FileReader();
                      return (
                        <div className="w-full rounded">
                          <img src={image.toString()} alt="uploaded image" />
                        </div>
                      );
                    })}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
