import React from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

const tabs = [
  { name: "Home", href: "/" },
  { name: "The Big Day", href: "/the-big-day" },
  { name: "Things to Do", href: "/things-to-do" },
  { name: "Travel & Accomodations", href: "/travel-accomodations" },
  { name: "FAQs", href: "/faq" },
  { name: "RSVP", href: "/rsvp" },
];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export default function Navbar() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  return (
    <div className="w-full mx-auto text-center py-4 px-1 sm:py-5 sm:px-1 lg:px-2">
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a page
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
          onChange={(e) => {
            e.preventDefault();
            const tab = tabs.find((tab) => tab.name === e.target.value);
            if (tab) {
              navigate(tab.href);
            }
          }}
          defaultValue={tabs.find((tab) => tab.href === pathname)?.name}
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav
            className="-mb-px flex  justify-center space-x-8"
            aria-label="Tabs"
          >
            {tabs.map((tab) => (
              <NavLink
                key={tab.name}
                to={tab.href}
                className={({ isActive }) =>
                  classNames(
                    isActive
                      ? "border-red-500 text-red-600"
                      : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                    "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                  )
                }
              >
                {tab.name}
              </NavLink>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
}
