import { Link } from "react-router-dom";

export function TravelAccomodations() {
  return (
    <div className="bg-white overflow-hidden">
      <div className="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <h2>
          <span className="mt-1 block text-4xl tracking-tight font-extrabold sm:text-5xl xl:text-6xl">
            <span className="text-gray-900">Travel &amp; Accomodations</span>
          </span>
        </h2>
        <h3>
          <span className="mt-2 block text-2xl tracking-tight font-extrabold sm:text-3xl xl:text-4xl">
            <span className="text-red-600">Getting In </span>
          </span>
        </h3>
        <p className="prose">
          If you're driving from the GTA, we recommend taking highway 401
          eastbound to highway 416. This route is usually the fastest way to get
          to the west side of the city.
        </p>
        <br />
        <p className="prose">
          If you're flying in, the closest airport is Ottawa International
          Airport (YOW). However, do note that YOW tends to have poor flight
          connections and flights can get fairly pricy. Other airports to
          consider include Montréal's Pierre Elliott Trudeau International
          Airport (YUL; a 2 hour drive to Ottawa) or Toronto's Lester B. Pearson
          International Airport (YYZ; a 5 hour drive to Ottawa), and renting a
          car.
        </p>
        <br />
        <p className="prose">
          Canada has opened up for fully-vaccinated international visitors.
          Details on COVID-19 specific entry requirements can be found{" "}
          <a
            href="https://travel.gc.ca/travel-covid/travel-restrictions/wizard-start"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          . Some visitors to Canada may need a visa or an Electronic Travel
          Authorization (eTA). Details on visa and eTA requirements can be found{" "}
          <a
            href="https://ircc.canada.ca/english/visit/visas.asp"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          .
        </p>
        <br />

        <h3>
          <span className="mt-2 block text-2xl tracking-tight font-extrabold sm:text-3xl xl:text-4xl">
            <span className="text-red-600">Getting Around</span>
          </span>
        </h3>
        <p className="prose">
          Though the city does have fantastic public transportation in the
          Downtown Core, it leaves a lot to be desired in suburban areas like
          Kanata and Carp. Consider renting a car, or using rideshare apps such
          as Lyft or Uber.
        </p>
        <br />
        <p className="prose">
          We will be providing a shuttle service between TownePlace Suites
          Ottawa Kanata and the venue on the day of the wedding. Details of this
          shuttle service are available <Link to="/the-big-day">here</Link>.
        </p>
        <br />

        <h3>
          <span className="mt-2 block text-2xl tracking-tight font-extrabold sm:text-3xl xl:text-4xl">
            <span className="text-red-600">Accomodations</span>
          </span>
        </h3>
        <p className="prose">
          We've worked with TownePlace Suites Ottawa Kanata to secure a
          preferred rate for our guests. The hotel will be offering two types of
          rooms: a 1-king-bed and sofa bed room, and a two-queen-bed room. These
          rooms can be booked with our link{" "}
          <a
            href="https://www.marriott.com/events/start.mi?id=1649879694741&key=GRP"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          . If you're staying in Ottawa for a bit longer, the hotel may also be
          able to honour the rate for a longer stay. Please{" "}
          <a href="mailto:info@akshayandtaylor.com">get in touch with us</a> if
          you'd like to do so.
        </p>
        <br />
        <p className="prose">
          Please note that the block expires on August 2, 2022, and that rooms
          may not be available at the block rate beyond that date.
        </p>
        <br />
        <p className="prose">
          If TownePlace Suites Ottawa Kanata does not suit your needs, you're
          more than welcome to explore alternatives. Nearby hotels include
          Homewood Suites Ottawa Kanata and Holiday Inn &amp; Suites Ottawa
          Kanata. We anticipate that our shuttle service will be able to stop at
          all three hotels. We recommend looking around the Kanata area, as many
          of the hotels in Ottawa are located in the downtown core, which is
          significantly further away. Airbnb and VRBO may also offer alternative
          lodging options more suited to your needs.
        </p>
        <br />
        <p className="prose">
          Ontario residents: save your receipts! The provincial government has
          announced the{" "}
          <a
            href="https://www.ontario.ca/page/ontario-staycation-tax-credit"
            target="_blank"
            rel="noreferrer"
          >
            Ontario Staycation Tax Credit
          </a>
          , subsidizing the cost of a leisure stay of less than a month in the
          province, at a short-term accommodation. More details can be found on
          the province's website.
        </p>
        <br />
      </div>
    </div>
  );
}
