import React from "react";

import couplePortrait from "../assets/images/couple-portrait.jpg";

export function RSVPComplete() {
  return (
    <>
      <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8">
          <div>
            <h2 className="mt-6 text-center text-2xl font-extrabold text-gray-900">
              Thanks for your RSVP!
            </h2>
            <p className="mt-2 text-gray-600 prose">
              If you need to make any changes, please reach out to us via{" "}
              <a href="mailto:info@akshayandtaylor.com">email</a>.
            </p>
          </div>
          <img src={couplePortrait} alt="A portrait of the couple" />
        </div>
      </div>
    </>
  );
}
