import { Carousel } from "../Carousel";

export function Home() {
  return (
    <div className="lg:grid lg:grid-cols-12 lg:gap-8">
      <div className="md:max-w-2xl md:mx-auto lg:col-span-6 text-left">
        <h2>
          {/* <span className="block text-sm font-semibold uppercase tracking-wide text-gray-500 sm:text-base lg:text-sm xl:text-base">
              Coming soon
            </span> */}
          <span className="mt-1 block text-4xl tracking-tight font-extrabold sm:text-5xl xl:text-6xl">
            <span className="text-gray-900">We're tying the </span>
            <span className="text-red-600">knot!</span>
          </span>
        </h2>

        <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl prose">
          <h2>Our Story</h2>
          <p>
            We first met on a chilly March evening at a small Irish pub in
            University Plaza beside campus after a day full of lectures and
            midterms. Over two plates of Irish Nachos (which really, should've
            been just one since we'd both eaten dinner already) we became quick
            friends.
          </p>
          <p>
            Living just two bus stops away didn't last long, as our respective
            coop programs quickly whisked us away to different ends of
            continent. For four long years, we made frequent trips back and
            forth to see each other across the world as school and careers
            brought us down different paths. Between trips to Deep River,
            Ontario, San Francisco, California and Dublin, Ireland, we somehow
            squeezed in time to travel the world together. To date, we have
            visited over 10 countries and countless states and provinces
            together.
          </p>
          <p>
            In March 2020, as the COVID lockdowns hit Canada, we finally moved
            into our first apartment together in Ottawa. To keep us busy during
            COVID, we started fostering Keegan for Canadian Guide Dogs for the
            Blind. We hope that, one day, Keegan will become a guide dog! Keegan
            goes on countless adventures with us, from trips to the local
            grocery store to road trips out east.
          </p>
        </p>
      </div>
      <div className="mt-4 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
        <div className="relative mx-auto w-full rounded-lg shadow-lg lg:max-w-md">
          <span className="sr-only">A few photos of us</span>
          <Carousel />
        </div>
      </div>
    </div>
  );
}
