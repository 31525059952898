const timeline = [
  {
    time: "4:25 PM",
    event: "Shuttle departs from TownePlace Suites to Aquatopia",
  },
  { time: "4:30 PM", event: "Guests arrive at Aquatopia" },
  { time: "5:00 PM", event: "Ceremony" },
  { time: "5:30 PM", event: "Cocktail Hour" },
  { time: "7:00 PM", event: "Dinner and speeches" },
  { time: "9:30 PM", event: "Dancing" },
  { time: "11:15 PM", event: "Late night snack" },
  {
    time: "11:25 PM",
    event: "Early shuttle departs from Aquatopia to TownePlace Suites",
  },
  { time: "12:30 AM", event: "Last call" },
  {
    time: "12:45 AM",
    event: "Late shuttle departs from Aquatopia to TownePlace Suites",
  },
  // { time: "12:30 AM", event: "Shuttle Departs from Aquatopia to Hilton Hotel" },
];

export function TheBigDay() {
  return (
    <div className="">
      <div className="sm:text-center md:max-w-2xl md:mx-auto lg:text-left">
        <h2>
          <span className="mt-1 block text-4xl tracking-tight font-extrabold sm:text-5xl xl:text-6xl">
            <span className="text-gray-900">The </span>
            <span className="text-red-600">Big</span>
            <span className="text-gray-900"> Day</span>
          </span>
        </h2>
        <div className="text-center mt-8 grid-cols-3 grid-rows-3 grid gap-4">
          <span className="text-3xl font-['Arkipelago']">Akshay Joshi</span>
          <span className="font-['Arkipelago']">&amp;</span>
          <span className="text-3xl font-['Arkipelago']">
            Taylor Kidd-Milne
          </span>
          <span>son of Anita and Samir Joshi</span>
          <span></span>
          <span>daughter of Debbie Kidd and Chris Milne</span>
        </div>
        <div>
          <br />
          request the honour of your presence at the celebration of their
          marriage, on September 16<sup>th</sup> 2022.
        </div>
        <div className="mt-4 text-base">
          <ul role="list" className="-mb-8">
            {timeline.map((event, eventIdx) => (
              <li key={`event-${eventIdx}`}>
                <div className="relative pb-8">
                  {eventIdx !== timeline.length - 1 ? (
                    <span
                      className="absolute top-4 left-4 -ml-2 h-full w-0.5 bg-gray-200"
                      aria-hidden="true"
                    />
                  ) : null}
                  <div className="relative flex space-x-3">
                    <div>
                      <span
                        className={
                          "h-5 w-5 rounded-full flex items-center justify-center ring-8 ring-white bg-red-600 block mt-3"
                        }
                        aria-hidden="true"
                      />
                    </div>

                    <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                      <div>
                        <p className="text-lg text-gray-500">{event.event}</p>
                      </div>
                      <div className="text-right text-lg whitespace-nowrap text-gray-500">
                        <time>{event.time}</time>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
