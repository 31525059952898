import React from "react";

const thingsToDo = [
  {
    name: "ByWard Market",
    link: "https://www.byward-market.com/",
    photo: require("../assets/images/byward-market.jpg"),
    photoCaption: "",
    text: "The oldest market in Canada, and home of the iconic Ottawa sign. We enjoy going out to the many restaurants and bars located around this area, as well as the smaller local merchants such as Paper Papier. If you're looking for a quick pick-me-up, try the Obama cookies at Le Moulin de Provence or the churros at La Catrina.",
  },
  {
    name: "Parliament Hill",
    link: "https://lop.parl.ca/sites/Visit/default/en_CA/Planning",
    text: "Home of Canada's legislature, and the nightly sound and light show detailing the key historical moments in Canadian history. If you're into geocaching, you're sure to find plenty of caches around the area, including Ottawa's only webcam cache at the Centennial Flame.",
    photo: require("../assets/images/parliament.jpg"),
    photoCaption: "",
  },
  {
    name: "NCC Parks",
    link: "https://ncc-ccn.gc.ca/places/parks",

    text: "Canada's National Capital Commission maintains various parks and trails throughout the city. These parks are a great way for Ottawa residents to get a slice of nature without leaving the city. We take our foster dog, Keegan, out on NCC trails daily. Though we'd always imagined getting engaged in Japan's cherry blossoms, we had to make do with the Dominion Arboretum's cherry blossoms during the COVID-19 lockdowns.",
    photo: require("../assets/images/ncc-parks.jpg"),
    photoCaption: "",
  },
  {
    name: "Ottawa River",
    text: "The Ottawa River forms the boundary between Ontario and Québec. We've enjoyed both swimming and sailing in the river during our time here. Across the city, there are multiple waterfront parks where you can enjoy scenic vistas and local ice cream. Britannia Beach and Andrew Hayden Park are two of our favourite places along the river.",
    photo: require("../assets/images/river-park.jpg"),
    photoCaption: "",
  },
  {
    name: "Ottawa Museums",
    text: "As the nation's capital, Ottawa is home to many museums around the city. Between museums for science and technology, art, history, nature and aviation, you're sure to find something of interest to you. Near our wedding venue, an old Cold War-era nuclear bunker is now a museum highlighting Canada's role in the Cold War.",
    photo: require("../assets/images/diefenbunker.jpg"),
    photoCaption: "",
  },
  {
    name: "The Glebe",
    text: "One of our favourite neighbourhoods in Ottawa, The Glebe offers it all! From small bookstores and coffee shops, to large patios and farmers' markets, this neighbourhood offers something different in every season. In the winters, we often come by and watch skaters on the Canal while enjoying a hot Beavertail, a great Canadian delicacy!",
    photo: require("../assets/images/the-glebe.jpg"),
    photoCaption: "",
  },
];

export function ThingsToDo() {
  return (
    <div className="bg-white overflow-hidden">
      <div className="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <h2>
          <span className="mt-1 block text-4xl tracking-tight font-extrabold sm:text-5xl xl:text-6xl">
            <span className="text-gray-900">Things to Do</span>
          </span>
        </h2>
        <h3>
          <span className="mt-2 block text-2xl tracking-tight font-extrabold sm:text-3xl xl:text-4xl">
            <span className="text-red-600">In Ottawa</span>
          </span>
        </h3>
        <p>
          We're so happy to welcome you to our hometown of Ottawa, the nation's
          capital. Ottawa is filled with many historical sites, museums and
          trails. We've listed a few of our favourites below, but by no means is
          this list exhaustive.
        </p>
        <br />
        {thingsToDo.map(({ name, link, photo, photoCaption, text }, idx) => {
          const isEven = idx % 2 === 0;
          const body = (
            <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
              <div
                className={`relative lg:row-start-1 ${
                  isEven ? "lg:col-start-2" : ""
                }`}
              >
                <div className="relative text-base mx-auto max-w-prose lg:max-w-none">
                  <figure>
                    <div className="aspect-w-12 aspect-h-7 lg:aspect-none">
                      <img
                        className="rounded-lg shadow-lg object-cover object-center"
                        src={photo}
                        alt={photoCaption}
                      />
                    </div>
                  </figure>
                </div>
              </div>
              <div className="mt-8 lg:mt-0">
                <div className="mx-auto text-base max-w-prose lg:grid lg:grid-cols-2 lg:gap-8 lg:max-w-none">
                  <div>
                    <h2 className="text-base text-red-600 font-semibold tracking-wide uppercase">
                      {name}
                    </h2>
                  </div>
                </div>
                <div className="text-base max-w-prose mx-auto lg:max-w-none">
                  <p className="text-lg text-gray-500">{text}</p>
                </div>
                {link && (
                  <div className="mt-5 prose prose-red text-gray-500 mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1">
                    <p>
                      For more details, please visit {name}'s{" "}
                      <a
                        className="text-red-600 hover:text-red-500"
                        href={link}
                        target="_blank"
                        rel="noreferrer"
                      >
                        website
                      </a>
                      .
                    </p>
                  </div>
                )}
              </div>
            </div>
          );

          return <div className="mt-8">{body}</div>;
        })}

        <h3>
          <span className="mt-2 block text-2xl tracking-tight font-extrabold sm:text-3xl xl:text-4xl">
            <span className="text-red-600">Near Ottawa</span>
          </span>
        </h3>

        <p>
          Here for a bit longer? Do check out Montréal's famous bagels and
          smoked meat, along with the Old City and the Montréal casino.
          Alternatively, if you're looking for something more active, check out
          Gatineau Park or Algonquin Provincial Park.
        </p>
      </div>
    </div>
  );
}
