import React from "react";
import Navbar from "./Navbar";
import Header from "./Header";
import { Link, Outlet, useLocation } from "react-router-dom";

function App() {
  const { pathname } = useLocation();
  return (
    <div className="App">
      <div className="flex flex-col">
        <Header />
        <Navbar />
        {/* Hacky but please forgive me, future me */}
        {pathname === "/the-big-day" && (
          <div className="mt-4 relative w-full lg:mt-0">
            <div className="relative w-full rounded-lg shadow-lg">
              <span className="sr-only">A photo of the venue</span>
              <img
                src={require("./assets/images/aquatopiawide.jpg")}
                className={"object-fill"}
              />
            </div>
          </div>
        )}
        <main className="mt-2 mx-auto max-w-7xl px-4 sm:mt-24 sm:px-6 lg:mt-4 mb-2 lg:mb-4">
          <Outlet />
        </main>
        <div className="bg-red-700">
          <div className="max-w-2xl mx-auto text-center py-12 px-4 sm:py-20 sm:px-6 lg:px-8">
            {/* <p>Aquatopia Conservatory</p>
            <p>2710 March Rd</p> */}
            <Link
              to="/rsvp"
              className="mt-8 w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-red-600 bg-white hover:bg-red-50 sm:w-auto"
            >
              RSVP
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
