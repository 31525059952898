const faqs = [
  {
    question: "What should I wear? Is there a dress code?",
    answer: [
      "The dress code for the event is traditional Indian attire or formal/cocktail Western attire. Please wear whatever is most comfortable for you!",
      "However, that being said, we will be wearing traditional Indian attire, and we'd love to see our guests embrace the beautiful colours and varied styles of Indian wear too. If you are unsure what to wear, or need suggestions on where to rent or purchase Indian clothing, please reach out!",
    ],
  },
  {
    question: "What will the weather be like this time of year?",
    answer: [
      "Typically, Septembers in Ottawa can bring somewhat warm days (in the low 20s) with cooler nights (dipping below 10°C). It's not unheard of to have the occasional day around 30°C, or even much lower in the single digits. We strongly encourage layering. Ah, the joys of fall!",
      "The venue itself may feel tropical as it is a fully-functional greenhouse. Light, breathable clothing is recommended.",
    ],
  },
  {
    question: "Where are the ceremony and the reception taking place?",
    answer: [
      "All events on the day of the wedding will be held at Aquatopia Conservatory, including the ceremony and reception.",
    ],
  },
  // {
  //   question: "What should I do if I can't make it?",
  //   answer:
  //     ["You will be missed! If you can't make it to our wedding, please let us know as soon as possible via the RSVP form so that we can plan accordingly."],
  // },
  {
    question: "What time should I arrive?",
    answer: [
      "The ceremony will commence promptly at 5 p.m. We encourage you to arrive around half an hour before the start of the ceremony.",
    ],
  },
  {
    question: "Where should guests park? Is parking free?",
    answer: [
      "Free parking is available at the venue. Cars may be parked overnight until 11 AM the next morning. However, we strongly encourage you to make use of an alternative transporation option such as the shuttle service, rideshare or taxi.",
    ],
  },
  // {
  //   question: "Are kids welcome?",
  //   answer: [""],
  // },
  {
    question: "Can I bring a date?",
    answer: ["Please check your invite for your +1!"],
  },
  {
    question:
      "Do you have a hotel block for guests? Where do you recommend I stay?",
    answer: [
      "Yes! Please see the Travel & Accomodations page for more information.",
    ],
  },
  {
    question:
      "When is the RSVP deadline? Do I need to mail back my RSVP or can I do it online?",
    answer: ["Please RSVP online on our website by August 1st."],
  },
  {
    question:
      "Will there be any vegan/vegetarian/gluten free/special diet options?",
    answer: [
      "Please note any dietary restrictions or preferences on your RSVP! We'll work with the venue to make sure you're accomodated.",
    ],
  },
  {
    question: "Will there be a bar at the event?",
    answer: [
      "Yes, there is an open bar at the event. We encourage you to enjoy responsibly and plan ahead in ensuring you have a safe way home. As the venue is somewhat rural, rideshares can be hard to book, especially late at night. We encourage guests to use our shuttle service to get to central Kanata, where availability of rideshare vehicles is considerably higher.",
    ],
  },
  {
    question: "Will transportation be provided between to/from the hotel?",
    answer: [
      "Yes, we will be providing a shuttle between the venue and TownePlace Suites Ottawa Kanata.",
    ],
  },
  {
    question: "Can I take and post pictures of the wedding on social media?",
    answer: [
      "We appreciate your enthusiasm! We've hired a photographer to take photos of the ceremony and we ask that guests put their phones away for this part of the event. We'd be happy to share these photos with you at a later time. For the remainder of the event, please feel free to take as many photos as you wish!",
    ],
  },
  {
    question: "Do you have a gift registry? Where are you registered?",
    answer: [
      "Having lived on our own so long, we’ve amassed everything we could possibly need for our home.  As such, we kindly request no boxed gifts.",
    ],
  },

  // More questions...
];

export default function FAQ() {
  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-20 lg:px-8">
        <div className="lg:grid lg:grid-cols-3 lg:gap-8">
          <div>
            <h2 className="text-3xl font-extrabold text-gray-900">
              <span className="text-gray-900">Frequently asked </span>
              <span className="text-red-600">questions</span>
            </h2>
            <p className="mt-4 text-lg text-gray-500 prose">
              Have a question we haven't yet answered? Please reach out to us
              via <a href="mailto:info@akshayandtaylor.com">email</a>!
            </p>
          </div>
          <div className="mt-12 lg:mt-0 lg:col-span-2 max-w-prose">
            <dl className="space-y-12">
              {faqs.map((faq) => (
                <div key={faq.question}>
                  <dt className="text-lg leading-6 font-medium text-gray-900">
                    {faq.question}
                  </dt>
                  <dd className="mt-2 text-base text-gray-500">
                    {faq.answer.map((x) => (
                      <>
                        <p>{x}</p>
                        <br />
                      </>
                    ))}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
}
